<template>
  <b-container>
    <b-row>

      <b-col cols="12">
        <b-card
            header="Labot lomu"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-col cols="12" class="text-center" v-if="!form.name">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Loma tika veiksmīgi atjaunota!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <p>{{ errorMessage }}</p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-form @submit="onSubmit" enctype="multipart/form-data" v-if="form.name">

            <b-form-group
                id="input-group-2"
                label="Nosaukums:"
                label-for="input-2"
            >

              <b-form-input v-model="form.name"></b-form-input>

            </b-form-group>

            <b-form-group label="Atļaujas:" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="form.permissions"
                  :aria-describedby="ariaDescribedby"
                  name="permissions"
              >
                <b-form-checkbox :value="p.name" v-for="p in permissions">{{ p.display_name }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <b-button type="submit" variant="success">Saglabāt</b-button>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "Create",
  data() {
    return {
      created: null,
      errorMessage: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      form: {
        name: null,
        permissions: [],
      },
      permissions: [],
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      console.log(this.form);

      axios.put('/roles/'+this.$route.params.id, this.form).then(response => {
        this.created = 'success';

      }).catch(error => {
        this.created = 'error';
        this.errorMessage = error;
      })

      this.dismissCountDown = 5;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    getData() {
      axios.get('/roles/'+this.$route.params.id).then(response => {
        let data = response.data.data;
        console.log(data);
        this.form.name = data.name;

        data.permissions.forEach(e => {
          this.form.permissions.push(e.name);
        });
      });

      axios.get('/permissions').then(response => {
        this.permissions = response.data.data;
      });
    },
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>